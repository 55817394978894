import {toNumber} from 'lodash';
import {KeyValueObject} from 'types';
import {gsm} from 'utils/globalStateManager';

export class Globals {

    static routeParams = {} as KeyValueObject;

    static useParams(params: KeyValueObject) {
        Globals.routeParams = params;
    }

    static getCompanyId(): number {
        return Globals.routeParams.CID ? toNumber(Globals.routeParams.CID) : 9999;
    }

    static activateLoading() {
        gsm.setLoading(true);
    }

    static disableLoading() {
        gsm.setLoading(false);
    }
}

