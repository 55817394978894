import ErrorModal from 'components/ErrorModal';
import LoadingOverlay from 'components/LoadingOverlay';
import moment from 'moment';
import 'moment/locale/et';
import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {TokenService} from 'services';
import {gsm} from 'utils/globalStateManager';
import PrivateRoute from 'views/auth/components/PrivateRoute';

const authPages = {
    forgotPassword: React.lazy(() => import ('views/auth/views/ForgotPassword')),
    restorePassword: React.lazy(() => import ('views/auth/views/RestorePassword')),
    login: React.lazy(() => import ('views/auth/views/Login')),
};

const pages = {
    profile: {
        profile: React.lazy(() => import('views/modules/profile/profile/Profile')),
        statistics: React.lazy(() => import('views/modules/profile/Statistics/Statistics')),
        comments: React.lazy(() => import('views/modules/profile/comments/Comments')),
    }
};
const MasterCRM = React.lazy(() => import('views/layouts/MasterCRM/MasterCRM'));

const App: React.FC = () => {
    moment.locale('et');
    const [isLoading, showLoading] = useState(true);
    gsm.withUseState(useState).init();

    useEffect(() => {
        if (isLoading) {
            const tokenService = new TokenService();
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.has('tokenId')) {
                tokenService.getById(queryParams.get('tokenId') ?? '').then(token => {
                    TokenService.store(token);
                    showLoading(false);
                });
            }
            else {
                showLoading(false);
            }
        }
    }, []);

    if (isLoading) {
        return <div>laen...</div>;
    }

    document.getElementsByTagName('title')[0].innerHTML = 'CRM';
    const pathRegex = /^\/company\/(\d+)/gm.exec(window.location.pathname);
    if (!pathRegex && !['/', '/login'].includes(window.location.pathname)) {
        return <div>Ettevõtte ID on puudu</div>;
    }
    return (
        <React.Suspense fallback={<div>laen...</div>}>
            <BrowserRouter>
                <Routes>
                    <Route path="/forgotPassword" element={authPages.forgotPassword}/>
                    <Route path="/restorePassword/:code" element={authPages.restorePassword}/>
                    <Route path={'/login'} element={authPages.login}/>
                    <Route path={'/company/:CID/profile/statistics/:subPage?'} element={(
                        <PrivateRoute>
                            <MasterCRM>
                                <pages.profile.statistics/>
                            </MasterCRM>
                        </PrivateRoute>
                    )}/>
                    <Route path={'/company/:CID/profile/:subPage?'} element={(
                        <PrivateRoute>
                            <MasterCRM>
                                <pages.profile.profile/>
                            </MasterCRM>
                        </PrivateRoute>
                    )}/>
                    <Route path={'/company/:CID/profile/comments/:subPage?'} element={(
                        <PrivateRoute>
                            <MasterCRM>
                                <pages.profile.comments/>
                            </MasterCRM>
                        </PrivateRoute>
                    )}/>
                </Routes>
                <ErrorModal/>
                <LoadingOverlay/>
            </BrowserRouter>
        </React.Suspense>
    );
};

export default App;
