import {CompanyAPIService, CompanyAPIServiceProps} from 'services/api/CompanyAPIService';
import {Arr} from 'utils/Arr';

export interface CompanyProfileServiceProps extends CompanyAPIServiceProps {
}

type flags = 'introduction'|'contacts'|'filials'|'logo'|'homepage'|'ratings';

interface FlagState {
    name: flags;
    on: boolean;
}

export class CompanyProfileService extends CompanyAPIService {
    constructor(props?: CompanyProfileServiceProps) {
        if (!props) {
            props = {} as CompanyProfileServiceProps;
        }
        const {path, ...rest} = props;
        const realPath = path ? `/${path}` : '';
        super({
            path: `profile${realPath}`,
            ...rest
        });
    }

    toggleFlag(flag: FlagState|FlagState[]) {
        return super.post('toggle', Arr.toArray(flag));
    }
}