import {APIService, APIServiceProps} from 'services';
import {CommonSuccessResponse, KeyValueObject, QueryParams} from 'types';

export interface CommonAPIActionProps extends APIServiceProps {
    reOrderEndpoint?: string; //defaults to 'reOrder'
    getByIDEndpoint?: string; //defaults to '$ID'
    loadEndpoint?: string; //defaults to 'get'
    submitEndpoint?: string; //defaults to 'save'
    deleteEndpoint?: string; //defaults to '$ID'
}

export class APIActionService extends APIService {

    private reOrderEndpoint = 'reOrder';
    private getByIDEndpoint = '';
    private loadEndpoint = 'get';
    private submitEndpoint = 'save';
    private deleteEndpoint = ''; //$ID will be added to end

    constructor(props: CommonAPIActionProps) {
        super(props);
        if (props.reOrderEndpoint) {
            this.reOrderEndpoint = props.reOrderEndpoint;
        }
        if (props.getByIDEndpoint) {
            this.getByIDEndpoint = props.getByIDEndpoint;
        }
        if (props.loadEndpoint) {
            this.loadEndpoint = props.loadEndpoint;
        }
        if (props.submitEndpoint) {
            this.submitEndpoint = props.submitEndpoint;
        }
        if (props.deleteEndpoint) {
            this.deleteEndpoint = props.deleteEndpoint;
        }
    }

    async getById(id: string|number, queryParams?: QueryParams): Promise<any> {
        const ep = this.getByIDEndpoint ? `${this.getByIDEndpoint}/` : '';
        return this.get(`${ep}${id.toString()}`, queryParams);
    }

    async reOrder(ids: number[], queryParams?: QueryParams): Promise<CommonSuccessResponse> {
        return this.patch(this.reOrderEndpoint, ids, queryParams);
    }

    async load(queryParams?: QueryParams): Promise<any> {
        return this.get(this.loadEndpoint, queryParams).then();
    }

    async getRecord(id: string|number): Promise<any> {
        return this.getById(id);
    }

    //###################

    async submit(payload: KeyValueObject, queryParams?: QueryParams): Promise<any> {
        return this.post(this.submitEndpoint, payload, queryParams);
    }

    async deleteById(id: string|number, queryParams?: QueryParams): Promise<CommonSuccessResponse> {
        const ep = this.deleteEndpoint ? `${this.deleteEndpoint}/` : '';
        return super.delete(`${ep}${id.toString()}`, queryParams);
    }

    async togglePublish(id: number, published: boolean): Promise<any> {
        return this.patch('modify', [{ID: id, patch: {published: published, savedOnly: false}}]);
    }

    async publishAll(featureTypeID: number): Promise<any> {
        return this.post('publishAll', {featureTypeID: featureTypeID});
    }
}