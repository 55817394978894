export class Is {

    static object(v: any): boolean {
        return Object.prototype.toString.call(v) === '[object Object]';
    }

    static defined(v: any): boolean {
        return typeof v !== 'undefined';
    }

    static array(v: any): boolean {
        return Array.isArray(v);
    }

    static number(v: any): boolean {
        return typeof v === 'number' && isFinite(v);
    }

    static string(v: any): boolean {
        return typeof v === 'string';
    }

    static func(v: any): boolean {
        return Object.prototype.toString.call(v) === '[object Function]';
    }

    static email(email: any): boolean {
        if (!Is.string(email)) {
            return false;
        }
        const re = /(^[a-zA-Z0-9_.]+[@][a-z0-9]+[.][a-z]+$)/;
        return re.test(email);
    }

    static equal(from: any, to: any) {
        return JSON.stringify(from) === JSON.stringify(to);
    }

    static objectHasEmptyValues(value: any): boolean {
        const keys = Object.keys(value);
        if (keys.length === 0) {
            return true;
        }
        return keys.some(f => !Is.empty(value[f]));
    }

    static empty(value: any, checkObjectKeys: boolean = false) {
        return value === undefined
            || value === null
            || (!value)
            || (Array.isArray(value) && value.length === 0)
            || (checkObjectKeys && Is.object(value) && Is.objectHasEmptyValues(value))
            || (typeof value === 'object' && Object.keys(value).length === 0)
            || (typeof value === 'string' && value.trim().length === 0)
            ;
    }

    static integerLike(value: any): boolean {
        return (
            (typeof value === 'number' && Number.isInteger(value)) ||
            (typeof value === 'string' && Number.isInteger(Number(value)))
        );
    };
}

