import {APIActionService} from 'services/api/APIActionService';
import {CompanyFirstUploadStatus} from 'types';

export class FirstUploadService extends APIActionService {
    constructor(hash: string) {
        super({
            path: `crm/company/firstUpload/${hash}`
        });
    }

    getStatus(): Promise<CompanyFirstUploadStatus> {
        return this.get('status');
    }
}